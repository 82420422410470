.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url('../../assets/img/background_CCIF.png');
    overflow-y: hidden;
    .section-1 {
        margin-bottom: 205px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .first {
            background-color:#6584CC;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            height: 80px;
            position: fixed;
            z-index: 10;
            bottom: 0;
            
            button {
                margin-right: 3.95vw;
                p {
                    color: #6584CC;
                    margin: 0;
                }
            }
            p {
                margin-left: 0.85vw;
            }
        }
        .second {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            width: 60%;

        }
    }

    .section-2 {
        width: 72.5%;
        // height: 600px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 90px;

        .image-container {
            position: relative;
            width: 29%;
            img {
                width: 100%;
                border: 2px solid #E6E6E6;
        }
    }
        // img {
        //     width: 40%;
        //     border: 2px solid #E6E6E6;
        //     border-radius: 10px;
        //     overflow: hidden;
        //     max-width: 570px;
        // }
        .content-container {
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            .first {
                :nth-child(3) {
                    margin-left: 0.7rem;
                }
                p {
                    display: inline;
                }
            }
        }
        

    }
    
    .section-3 {
        // height: 685px;
        width: 72.5%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 90px;

        .image-container {
            position: relative;
            width: 29%;
            img {
                width: 100%;
            }
            .icon-setting {
                position: absolute;
                right: 0;
                width: 44%;
            }
            .icon-setting-left {
                position: absolute;
                left: 0;
                width: 44%;
            }
        }

        .content-container {
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1rem;
            .first {
                flex-wrap: wrap;
                word-wrap:inherit;


                :nth-child(3) {
                    margin-left: 0.7rem;
                }
                p {
                    display: inline;
                }
            }
            .second {
                width: 75%;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .btn p {
                    color: #6584CC;
                }
            }
        }
    }
}
.ccif-img-mobile{
    width: 200px;
    position: absolute;
    top: 32px;
    right: 0;
    // background: white;
    padding: 24px;
    border-radius: 10px 0px 0px 10px;
    display:none
}
main {
    background-image: url('../../assets/img/FribourgOPEN_Banner-4.jpg');
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;
    width: 92%;
    // height: auto;
    border-radius: 10px;
    margin-top: 40px;
    margin-bottom: 188px;
    padding: 168px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .content {
        margin: 0;
        display: flex;
        align-items: end;
        width: 100%;
        height: 100%;
        background-color: transparent;
        .text-content {
            width: 47.5%;
            // height: 363px;
            // position: absolute;
            padding: 0 0 0 8vw;
            display: flex;
            align-self: center;
            flex-direction: column;
            gap: 2rem;
            .navlink {
                text-decoration: none;
                justify-content: flex-start;
            }
        }
        img {
            width: 25%;
            position: absolute;
            top:0;
            right:0;
            padding: 24px;
            border-radius: 10px 0px 0px 10px;
            // background-color: white;
        }
    }
}

.navlink {
    text-decoration: none;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1650px) {
    main {
        // height: 545px;
        margin-top: 31.5px;
        margin-bottom: 110px;
        .content {
            align-items: center;
            .text-content {
                height: 100%;
                width: 67%;
                padding: 0 8vw 0 8vw;
            }
            img {
                align-self: end;
            }
        }
    }
    .home-container {
        .section-1 {
            margin-bottom: 130px;
        }
        // .section-2 {
        //     // margin-bottom: -20px;
        // }
        .section-3 {
            margin-bottom: 130px;
            .content-container {
                width:55%;
                .second {
                    width: 80%;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    main {
        // height: 625px;
        margin-top: 31.5px;
        margin-bottom: 80px;

        .content {
            align-items: center;
            .text-content {
                height: 100%;
            }
        }
    }
    .home-container {
        .section-1 {
            margin-bottom: 145px;
        }
        .section-2 {
            margin-bottom: 145px;
            // margin-bottom: -15px;
        }
        
        .section-3 {
            margin-bottom: 145px;
            .content-container {
                .second {
                    width: 100%;
                }
            }
            // margin-bottom: -60px;

        }
    }

}

@media screen and (max-width: 1200px) {
    .ccif-img-mobile{
        display:block;
    }
    main {
        // padding-top: 80px;
        // height: 700px;
        margin-top: 104px;
        // margin-bottom: 55px;
        display: flex;
        justify-content: flex-start;
        padding: 208px 0;
        .content {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: transparent;
            .text-content {
                width: 100%;
                // height: 500px;
                // position: absolute;
                // left: 8vw;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                // align-self: flex-end;
            }
            img {
                right: -2px;
                display:none
            }
        }
    }

.first-section-subtitle{
    margin-top:16px;
}


    .home-container {

        .section-1 {
            display: flex;
            flex-direction: column;
            align-items: center;

            // .second {
                
            //     // margin-bottom: 25px;
            // }
        }
        // .section-2 {
        //     // margin-bottom: 100px;

        // }
        .section-3 {
            // margin-top: 50px;
            // margin-bottom: 130px;
            display: flex;

            justify-content: space-between;
            align-items: center;
        }
    }
}


@media screen and (max-width: 900px) {
    .ccif-img-mobile{
        display:block;
    }
    main {
        // height: 700px;

        margin-bottom: 80px;
        display: flex;
        justify-content: flex-start;
        .content {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: transparent;
            .text-content {
                
                // height: 500px;
                // position: absolute;
                padding: 0 8vw;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                // align-self: flex-end;
                .navlink {
                    text-decoration: none;
                    justify-content: center;
                }
            }
            img {
                width: 250px;
                position: absolute;
                top: -168px;
                right: -2px;
                padding: 24px;
                border-radius: 10px 0px 0px 10px;
                background-color: white;
            }
        }
    }
    .home-container {

        .section-1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            .first {
                padding: 0 16px;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: auto;
                text-align: center;
                button {
                    margin-right: 0px !important;

                }
                > * {
                    margin: 16px 0;
                }
            }
            .second {

                width: 85%;
            }
        }
        .section-2 {
            width: 85%;
            height: auto;
            margin-bottom: 145px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            .content-container {
                width: auto;
                margin-bottom: 32px;
                .first {
                    width: auto;
                    margin-bottom: 16px;
                }
                .second {
                    width: auto;
                }
            }
            .image-container {
                width: 70%;
            }
        }

        .section-3 {
            width: 85%;
            height: auto;
            // margin-top: 50px;
            // margin-bottom: 130px;
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: center;
            .image-container {
                width: 70%;
            }
            .content-container {
                width: auto;
                margin-bottom: 32px;
                .first {
                    width: auto;
                    margin-bottom: 16px;
                }
                .second {
                    width: auto;
                }
            }
        }
    }


    .section-3:nth-child(6) {
        .content-container {
            order: 2;
        }
        .image-container {
            order: 1;
        }
        
    }
}

